.footer-container {
  background: linear-gradient(6deg, rgb(15, 15, 15) 35%, rgb(53, 49, 49) 100%);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-links {
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  text-align: center;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

.footer-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
}

.website-rights {
  color: #fff;
}

.footer-subinfo {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.footer-subinfo p {
  margin: 10px;
}

@media screen and (max-width: 820px) {
  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}
p {
  color: #fff;
  text-align: center;
}

.name {
  font-weight: bold;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .footer-subinfo {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .footer-subinfo p {
    margin: 10px;
  }
}
